import React from "react";
import _ from "lodash";

import { safePrefix } from "../utils";

export default class Author extends React.Component {
  render() {
    const people = _.get(this.props, "pageContext.site.data.people.people");
    const author = people.find(p => p.id === this.props.author);

    return (
      <div className="post-author">
        <footer className="testimonial-footer">
          <div className="author-avatar">
            <div className="author-avatar-background"></div>
            <img
              src={safePrefix(author.image)}
              alt="Author avatar"
            />
          </div>
          <div>
            <span className="post-author-name">{author.name}</span>
            {author.title && (
              <span className="post-author-title">{author.title}</span>
            )}
          </div>
        </footer>
      </div>
    );
  }
}
