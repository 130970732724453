import React from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import useSiteMetadata from "../hooks/use-site-metadata";

const MetaHead = (props) => {
  const { siteURL } = useSiteMetadata();

  const siteTitle =
    _.get(props, "pageContext.frontmatter.title") &&
    _.get(props, "pageContext.frontmatter.title") +
      " - " +
      _.get(props, "pageContext.site.siteMetadata.title");

  const excerpt = _.get(props, "pageContext.frontmatter.excerpt");

  const description =
    excerpt !== undefined && excerpt
      ? excerpt
      : "Turn your lab-developed software into a market-ready prototype and a foundation for fast & efficient research.";

  const imgPath = _.get(props, "pageContext.frontmatter.content_img_path")
    ? `${siteURL}/${_.get(props, "pageContext.frontmatter.content_img_path")}`
    : "https://codeclearlabs.com/images/CCL_full_square.jpg";

  return (
    <Helmet>
      <title>{siteTitle}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta itemprop="name" content={siteTitle} />
      <meta itemprop="description" content={description} />
      <meta name="image" content={imgPath} />
      <meta itemprop="image" content={imgPath} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={description}></meta>
      <meta property="og:title" content={siteTitle} />
      <meta property="og:image" content={imgPath} />
      <meta property="og:url" content={`${siteURL}${props.location.pathname}`} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        href="https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,600,600i,700,700i&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default MetaHead;
