import React from "react";

import { safePrefix, markdownify } from "../utils";

export default class PersonProfile extends React.Component {
  render() {
    const person = this.props.person;

    return (
      <div className="team-member-wrapper">
        <div className="team-avatar">
          <div className="team-avatar-background"></div>
          <img
            src={safePrefix(person.image)}
            alt="Team member avatar"
          />
        </div>

        <div className="team-text-wrapper">
          <cite className="post-author-name">{person.name}</cite>
          {person.title && (
            <cite className="post-author-title">{person.title}</cite>
          )}
          {person.bio && (
            <div className="team-bio">{markdownify(person.bio)}</div>
          )}
        </div>
      </div>
    );
  }
}
