import React from "react";
import _ from "lodash";

import { htmlToReact, markdownify } from "../utils";

export default class ReasonsBlock extends React.Component {
  render() {
    return (
      <section
        id={_.get(this.props, "section.section_id")}
        className="block services-block outer"
      >
        <div className="inner">
          <div className="block-inside">
            <div className="block-header">
              <h2 className="block-title line-top">
                {_.get(this.props, "section.title")}
              </h2>
              {_.get(this.props, "section.subtitle") && (
                <p className="block-subtitle">
                  {htmlToReact(_.get(this.props, "section.subtitle"))}
                </p>
              )}
            </div>
            {_.get(this.props, "section.reasonslist") && (
              <div className="block-content">
                <div className={"services-list"}>
                  {_.map(
                    _.get(this.props, "section.reasonslist"),
                    (service, service_idx) => (
                      <div
                        key={service_idx}
                        className={
                          "service" +
                          (_.get(this.props, "section.cols") !== undefined
                            ? " cols-" + _.get(this.props, "section.cols")
                            : " cols-2")
                        }
                      >
                        {_.get(service, "fa_icon") && (
                          <div
                            className={
                              "reason-icon" +
                              (_.get(service, "icon_color") !== undefined
                                ? " color-" + _.get(service, "icon_color")
                                : "")
                            }
                          >
                            <i className={"las la-" + _.get(service, "fa_icon")}/>
                          </div>
                        )}
                        {_.get(service, "title") && (
                          <h3 className="service-title">
                            {_.get(service, "title")}
                          </h3>
                        )}
                        <div className="service-text">
                          {markdownify(_.get(service, "content"))}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
