import React from "react";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { name: null, email: null, message: null };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  canSubmit = () => {
    return this.state.name && this.state.email && this.state.message;
  };

  render() {
    return (
      <form
        name="contactForm"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        id="contact-form"
        className="contact-form"
      >
        <p className="screen-reader-text">
          <label>
            Don't fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <p className="form-row">
          <label className="form-label" htmlFor="contact-user-name">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="contact-user-name"
            className="form-input"
            placeholder="Your name"
            onChange={this.handleChange}
          />
          <span className="input-focus" aria-hidden="true" />
        </p>
        <p className="form-row">
          <label className="form-label" htmlFor="contact-user-email">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="contact-user-email"
            className="form-input"
            placeholder="Your email address"
            onChange={this.handleChange}
          />
          <span className="input-focus" aria-hidden="true" />
        </p>
        <p className="form-row">
          <label className="form-label" htmlFor="contact-message">
            Message
          </label>
          <textarea
            name="message"
            id="contact-message"
            className="form-textarea"
            rows="5"
            placeholder="How can we help?"
            onChange={this.handleChange}
          />
          <span className="input-focus" aria-hidden="true" />
        </p>
        <input type="hidden" name="form-name" value="contactForm" />
        <p className="form-row form-submit">
          <button type="submit" className="button" disabled={!this.canSubmit()}>
            {this.canSubmit() ? "Send Message" : "Enter Your Info"}
          </button>
        </p>
      </form>
    );
  }
}
