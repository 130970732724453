import React from "react";
import _ from "lodash";

import Header from "./Header";
import Footer from "./Footer";
import MetaHead from "./MetaHead";

import "./../sass/main.scss";


export default class Body extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MetaHead {...this.props} />
        <div
          id="page"
          className={
            "site palette-" +
            _.get(this.props, "pageContext.site.siteMetadata.color_scheme") +
            " accent-" +
            _.get(this.props, "pageContext.site.siteMetadata.accent_color")
          }
        >
          <Header {...this.props} />
          <main id="content" className="site-content">
            {this.props.children}
          </main>
          <Footer {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}
