import React from "react";
import _ from "lodash";

import { Link, safePrefix } from "../utils";

export default class Header extends React.Component {
  render() {
    const logoUrl = _.get(
      this.props,
      "pageContext.site.siteMetadata.header.logo_img"
    );

    const brand = logoUrl ? (
      <img src={safePrefix(logoUrl)} className="logo-img" alt="Logo" />
    ) : (
      <span>
        <span key="left-bracket" className="site-brand-bracket">
          &lt;
        </span>
        <span key="brand-1" className="site-brand-part1">
          {_.get(
            this.props,
            "pageContext.site.siteMetadata.header.brand.part1"
          )}
        </span>
        <span key="brand-2" className="site-brand-part2">
          {_.get(
            this.props,
            "pageContext.site.siteMetadata.header.brand.part2"
          )}
        </span>
        <span key="right-bracket" className="site-brand-bracket">
          &gt;
        </span>
      </span>
    );

    return (
      <header id="masthead" className="site-header outer">
        <div className="inner">
          <div className="site-header-inside">
            <div className="site-branding">
              {_.get(
                this.props,
                "pageContext.site.siteMetadata.header.logo_img"
              ) && (
                <p className="site-logo">
                  <Link to={safePrefix("/")}>{brand}</Link>
                </p>
              )}
              {_.get(this.props, "pageContext.frontmatter.template") ===
                "home" ||
              _.get(this.props, "pageContext.frontmatter.template") ===
                "blog" ? (
                <h1 className="site-title">
                  <Link to={safePrefix("/")}>{brand}</Link>
                </h1>
              ) : (
                <p className="site-title">
                  <Link to={safePrefix("/")}>{brand}</Link>
                </p>
              )}
            </div>
            {_.get(this.props, "pageContext.menus.main") &&
              _.get(
                this.props,
                "pageContext.site.siteMetadata.header.has_nav"
              ) && (
                <React.Fragment>
                  <nav
                    id="main-navigation"
                    className="site-navigation"
                    aria-label="Main Navigation"
                  >
                    <div className="site-nav-inside">
                      <button id="menu-close" className="menu-toggle">
                        <span className="screen-reader-text">Open Menu</span>
                        <span className="icon-close" aria-hidden="true" />
                      </button>
                      <ul className="menu">
                        {_.map(
                          _.get(this.props, "pageContext.menus.main"),
                          (item, item_idx) => (
                            <li
                              key={item_idx}
                              className={
                                "menu-item " +
                                (_.get(this.props, "pageContext.url") ===
                                _.get(item, "url")
                                  ? " current-menu-item"
                                  : "")
                              }
                            >
                              <Link to={safePrefix(_.get(item, "url"))}>
                                {_.get(item, "title")}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </nav>
                  <button id="menu-open" className="menu-toggle">
                    <span className="screen-reader-text">Close Menu</span>
                    <span className="icon-menu" aria-hidden="true" />
                  </button>
                </React.Fragment>
              )}
          </div>
        </div>
      </header>
    );
  }
}
